export default defineAppConfig({
  showTeaserDescription: {
    large: false,
    medium: false,
    small: false,
    tiny: false,
    default: false,
    ordered: false,
  },

  teaserlist: {
    theme: {
      hasBackground: false,
    },
    ordered: {
      hasBackground: 'contrast',
      items: '3',
    },
    slider: {
      hasBackground: 'themed',
    },
    presntion: {
      hasBackground: 'contrast',
    },
    default: {
      hasBackground: false,
    },
    latestNews: {
      hasBackground: false,
    },
  },

  teaserItem: {
    extraLarge: {
      labelOnImage: true,
      showTeaserDescription: false,
      showImage: true,
    },
    large: {
      labelOnImage: true,
      showTeaserDescription: true,
      showImage: true,
    },
    medium: {
      labelOnImage: true,
      showTeaserDescription: false,
      showImage: true,
    },
    small: {
      labelOnImage: true,
      showTeaserDescription: false,
      showImage: true,
    },
    tiny: {
      labelOnImage: false,
      showTeaserDescription: false,
      showImage: false,
    },
    default: {
      labelOnImage: true,
      showTeaserDescription: false,
      showImage: true,
    },
    ordered: {
      labelOnImage: false,
      showTeaserDescription: false,
      showImage: false,
    },
    video: {
      labelOnImage: true,
      showTeaserDescription: false,
      showImage: true,
    },
  },

  useHeroBanner: false,
  isPrimaryTextAlignCentered: false,
  withTeaserCategories: false,
  showBurgerMenu: true,
  showLogin: false,
  fallbackHeroImage: true,
  hasShortHeaderLogo: false,
  page: {
    category: {
      layout: 'default',
      bannerTemplate: 'onlyFirst',
      darkmode: false,
      hasStickyBanner: false,
    },
    frontpage: {
      layout: 'default',
      bannerTemplate: 'onlyFirst',
      darkmode: false,
      hasStickyBanner: false,
    },
    video: {
      layout: 'video',
      bannerTemplate: 'default',
      darkmode: false,
      hasStickyBanner: false,
    },
    authors: {
      layout: 'default',
      bannerTemplate: 'default',
      darkmode: false,
      hasStickyBanner: false,
    },
  },
  category: {
    layout: 'default',
    bannerTemplate: 'onlyFirst',
    hasStickyBanner: false,
  },
  siteSpecific: {
    brands: {
      bom: {
        icon: {
          tag: 'bolig-magasinet',
          name: 'bom_icon',
        },
      },
      liv: {
        icon: {
          tag: 'magasinet-liv',
          name: 'liv_icon',
        },
      },
      cos: {
        icon: {
          tag: 'costume',
          name: 'cos_icon',
        },
      },
      test: {},
    },
  },
  languageSpecefic: {
    languages: {
      dk: {
        socialShare: [
          {
            type: 'link',
            link: 'https://www.facebook.com/bilmagasinet',
            icon: 'facebook',
            description: 'share on facebook',
          },
          {
            type: 'copy',
            icon: 'link',
            style: '',
            description: 'copy link',
          },
        ],
      },
    },
  },

  contentTypes: {
    video: {
      layout: 'default',
      bannerTemplate: 'default',
      hasFrontPageLoaded: true,
      hasStickyBanner: false,
    },
    article: {
      layout: 'default',
      bannerTemplate: 'premium',
      hasFrontPageLoaded: true,
      hasStickyBanner: false,
    },
    blog: {
      layout: 'blog',
      bannerTemplate: 'default',
      hasFrontPageLoaded: true,
      hasStickyBanner: false,
    },
  },
})
